import React, { useState } from "react"
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub, faDribbble, faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"

import { RoughNotation } from "react-rough-notation"

const ContactPage = () => {

    const [formState, setFormState] = useState({
        name: '',
        email: '',
        message: '',
      });
    
      const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }
    
      const handleChange = e => {
        setFormState({
          ...formState,
          [e.target.name]: e.target.value,
        })
        console.log(formState);
      }
    
      const handleSubmit = e => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "Contact", ...formState })
        })
        .then(() => navigate("/thank-you/")).catch(error => alert(error));
    
        e.preventDefault();
      }

    return (
  <>
    <Seo title="Contact" />
    <section className={`fix-header contact-section`}>
      <div className={`content--md-center`}>
          <h6 className={`center primary`}>Get in touch</h6>
          <h1 className={`center`}>Let's talk</h1>
          <p className={`primary center`}>You can reach out to me by simply writing your message below or write to me at <a>thukral.abhinav@gmail.com</a> if you prefer emails. You can also find me at:</p>
          <div className={`socials`}>
              <a href={`https://github.com/AbhinavThukral97`} target={`_blank`}><button className={`sm`}><span className={`icon`}><FontAwesomeIcon icon={faGithub} /></span> Github</button></a>
              <a href={`https://dribbble.com/abhinavthukral`} target={`_blank`}><button className={`sm`}><span className={`icon`}><FontAwesomeIcon icon={faDribbble} /></span> Dribbble</button></a>
              <a href={`https://www.linkedin.com/in/thukralabhinav/`} target={`_blank`}><button className={`sm`}><span className={`icon`}><FontAwesomeIcon icon={faLinkedinIn} /></span> LinkedIn</button></a>
          </div>
      </div>
    </section>
    <section className={`fix-top fix-bottom`}>
      <div className={`content--md-center`}>
        <form name="Contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" onSubmit={handleSubmit} className={`add-margin-top add-margin-bottom`}>
              <p className={`label primary`}>Name</p>
              <input type='text' name='name' id='name' placeholder='Your full name' onChange={handleChange} value={formState.name} required />
              <p className={`label primary`}>Email</p>
              <input type='email' name='email' id='email' placeholder='Your e-mail address' onChange={handleChange} value={formState.email} required />
              <p className={`label primary`}>Message</p>
              <textarea name='message' id='message' placeholder='Start typing' onChange={handleChange} value={formState.message} required></textarea>
              <button className={`data-hover`}><span className={`icon-left`}><FontAwesomeIcon icon={faPaperPlane} /></span> Send message</button>
          </form>
      </div>
    </section>
  </>
    )
}

export default ContactPage
